import React from "react";
import "./Linebreak.css";
import linebreakleft from "../assets/linebreakleft.png";

function Linebreakleft() {
  return (
    <div className="linebreak">
      <img src={linebreakleft} alt="linebreak" />
    </div>
  );
}

export default Linebreakleft;
